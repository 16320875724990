.cupertino-pane-wrapper {
  --cupertino-pane-border-radius: 24px;
  --cupertino-pane-background: #F6F7F9;
  --cupertino-pane-icon-close-color: #FFFFFF;
  --cupertino-pane-destroy-button-background: #A3A4AC;

  .pane {
    padding: 0;
  }

  .destroy-button {
    width: 20px;
    height: 20px;
    top: 10px;
    right: 15px;
  }

  .evaluation-pane {
    border-radius: 24px;
  }
}

.feedback-pane-wrapper {
  --cupertino-pane-background: #FFFFFF;
}

.billing-pane-wrapper {
  --cupertino-pane-background: #FFFFFF;
}
