.primary-outline-button {
  border: 2px solid #FF8A00;
  border-radius: 8px;
  color: #FF8A00;
  font-family: "Raleway Medium", serif;
  font-size: 18px;
  height: 54px;
  width: 100%;
}

.primary-button {
  font-family: "Raleway Medium", serif;
  font-size: 18px;
  background: #FF8A00;
  border-radius: 8px;
  width: 100%;
  border: none;
  height: 54px;
}

.success-button {
  font-family: "Raleway Medium", serif;
  font-size: 18px;
  background: #1F9F43;
  border-radius: 8px;
  width: 100%;
  border: none;
  height: 54px;
}

.cancel-button {
  border: none;
  background: #0000001A;
  border-radius: 8px;
  height: 54px;
  width: 100%;
  font-family: "Raleway Medium", serif;
  font-size: 18px;
  color: #000000;
}

.primary-link {
  width: 100%;
  font-size: 18px;
  color: #FF8A00;

  &-underline {
    text-decoration: underline;
    width: 100%;
    font-size: 18px;
    color: #FF8A00;
  }

  &:hover {
    color: #ffa133;
  }
}

.small-link {
  font-size: 14px;
  text-decoration: underline;
  color: #FF8A00;

  &:hover {
    color: #ffa133;
  }
}

.default-outline-button {
  border: 2px solid #F6F7F9;
  border-radius: 8px;
  color: #2D2E2F;
  font-family: "Raleway Medium", serif;
  font-size: 18px;
  height: 54px;
  width: 100%;
}
