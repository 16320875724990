.el-form-item {
  margin: 0 0 10px 0;

  @import "components/inputs";

  &__label {
    font-family: "Raleway Medium", serif;
    font-size: 16px;
    color: #000000;
    padding: 0 !important;
  }

  .password-description {
    font-family: "Raleway Regular", serif;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 10px;
  }
}

.input-group {
  margin-top: 20px;

  &-title {
    margin: 0;
    font-family: "Raleway Medium", serif;
    font-size: 20px;
    color: #000000;
  }

  &-item {
    margin-left: 10px;
  }
}
