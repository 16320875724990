.custom-date-picker {
  @media(max-width: 768px) {
    left: 0 !important;
    width: 100% !important;
    top: 0 !important;
  }

  .el-picker-panel {
    &__body {
      @media(max-width: 768px) {
        display: flex;
        flex-direction: column;
        max-width: 100% !important;
        min-width: 0 !important;
      }
    }

    &__content {
      @media(max-width: 768px) {
        width: 100%;
      }

      .el-date-range-picker__header {
        div {
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
