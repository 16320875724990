.auth {
  &-title {
    text-align: center;
    font-family: 'Raleway Bold', serif;
    font-size: 20px;
    color: #000;
  }

  &-logo {
    margin: 0 auto;
    display: flex;
  }

  &-subtitle {
    font-family: 'Raleway Medium', serif;
    font-size: 18px;
  }

  .go-back-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 2px solid #000000;
    border-radius: 8px;
    cursor: pointer;
  }

  .align-center {
    text-align: center;
  }
}
