.burger {
  &-button {
    position: absolute;
    top: 40px;
    border: 2px solid #FFFFFF1A;
    border-radius: 8px;
    padding: 10px;

    &-line {
      background: #ffffff;
      height: 3px;
      width: 20px;
      border-radius: 48px;
    }
  }

  &-menu-drawer {
    .el-drawer__body {
      padding: 60px 25px 0 25px;

      .burger-menu {
        &-header {
          display: flex;
          align-items: center;
        }

        &-close-btn {
          width: 40px;
          height: 40px;
          padding: 0;
          border: 2px solid #0000001A;
          border-radius: 8px;
          color: #000000;

          i {
            font-weight: 600;
          }
        }

        &-title {
          font-family: "Raleway Bold", serif;
          font-size: 20px;
          color: #000000;
        }

        &-main {
          .el-main-menu, .el-additional-menu {
            border: none;
          }
        }
      }
    }
  }
}
