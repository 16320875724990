@import 'element/index';

@import "helpers/fonts";
@import "helpers/spacing-helpers";
@import 'helpers/animations';

@import 'header';
@import 'auth';
@import 'form';

@import "components/burger";
@import "components/collapse";
@import "components/popup";
@import "components/buttons";
@import "components/tabs";
@import "components/datepicker";
@import "components/pane";
@import "components/croppImage";

body {
  margin: 0;
}

.el-main {
  margin: 0;
  padding: 30px 16px 0 16px;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}

input[type="radio"] {
  border: none;
}

#paypal-button {
  .paypal-buttons {
    height: 56px !important;
  }
}