.confirm-dialog {
  text-align: center;

  .el-dialog {
    padding: 24px;
    border-radius: 15px;
  }

  .el-dialog__title {
    color: #090A0A;
    font-family: "Raleway Bold", serif;
    font-size: 24px;
  }

  .el-dialog__body {
    padding: 8px 0 24px 0;
    color: #72777A;
    font-family: "Raleway Regular", serif;
    font-size: 16px;
    text-align: center;
    // white-space: nowrap;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
