.app-header {
  background: #FF8A00;
  border-radius: 0 0 32px 32px;


  &-title {
    color: #ffffff;
    text-align: center;
    font-family: "Raleway Bold", serif;
    font-size: 20px;
  }

  &-name {
    color: #ffffff;
    text-align: center;
    font-family: "Raleway Bold", serif;
    font-size: 24px;
  }

  &-logo {
    display: flex;
    margin: 0 auto;
  }

  &-avatar {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;

    &-image {
      height: 115px;
      width: 115px;
      background-size: contain;
      border-radius: 50%;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-uploader {
      text-align: center;

      .upload-button {
        color: #FF8A00;
        border: none;
        border-radius: 48px;
        height: 26px;
        width: 116px;
        padding: 5px 10px;
        font-family: "Raleway Medium", serif;
        font-size: 14px;
      }
    }
  }
}

.avatar-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
