input {
  font-family: "Raleway Regular", serif;
  font-size: 16px;
  background: #FFFFFF;
  height: 50px;
  border: 1px solid #E3E5E6;
  border-radius: 8px;
  color: #000000 !important;

  &::placeholder {
    opacity: 0.5;
  }
}

textarea {
  font-family: "Raleway Regular", serif;
  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #E3E5E6;
  border-radius: 8px;
  color: #000000 !important;
  resize: none;

  &::placeholder {
    opacity: 0.5;
  }
}

.el-select {
  width: 100%;
}

.custom-phone-input {
  border: 1px solid #E3E5E6;
  border-radius: 8px;
  height: 50px;
  background: #FFFFFF;

  input {
    border: none;
    background: transparent;
  }

  .vti__dropdown {
    font-size: 16px;
    color: #000000;

    &-list {
      width: 320px;
    }

    .vti__country-code {
      color: #2D2E2F;
      font-family: "Raleway Bold", serif;
      font-size: 16px;
    }
  }
}

.el-rate {
  height: 100%;
  text-align: center;
  margin: 12px 0 0 0;
  outline: none;

  &__item {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &__icon {
    font-size: 40px;
    margin-right: 20px;

    &:hover {
      transform: scale(1);
    }
  }
}

.el-radio {
  &-group {
    display: flex;
  }

  &-button {
    margin-left: 8px;
    box-shadow: none !important;

    &:first-child {
      margin-left: 0;
    }

    &__inner {
      height: 80px;
      width: 80px;
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 5px solid #0000001A !important;
      font-family: "Raleway Bold", serif;
      font-size: 22px;
      box-shadow: none !important;
    }

    &.is-active {
      box-shadow: none;

      .el-radio-button__inner {
        border: none;
      }
    }
  }
}

.el-switch {
  &__label {
    span {
      font-family: "Raleway Bold", serif;
      font-size: 18px;
    }
  }
}

.el-range-editor {
  height: 50px;
  border: none;

  input {
    font-family: "Raleway Regular", serif;
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #E3E5E6;
    border-radius: 8px;
    color: #000000 !important;

    &::placeholder {
      opacity: 0.5;
    }
  }
}

.el-date-editor {
  width: 100%;
}

.el-input-number {
  width: 100%;

  input {
    text-align: left;
  }
}
