.el-collapse {
  border: none;

  .el-collapse-item {
    margin-bottom: 20px;
    border-radius: 15px;
    border: none;

    &.primary-outline {
      border: 1px solid #FF8A00;

      .el-collapse-item__arrow {
        color: #FF8A00;

        &.is-active {
          color: #ffffff;
        }
      }

      .el-collapse-item__header {
        &.is-active {
          background: #FF8A00;
        }
      }
    }

    &.confirmed {
      background-color: #BEE7CA80;

      .el-collapse-item__header {
        background-color: #BEE7CA;
      }
    }

    &.unconfirmed {
      background-color: #FFC98980;

      .el-collapse-item__header {
        background-color: #FFC989;
      }
    }

    &.not-assigned {
      background-color: #FFB98980;

      .el-collapse-item__header {
        background-color: #FF8989;
      }
    }

    .el-collapse-item__arrow {
      color: #ffffff;
      font-weight: 700;
    }

    .el-collapse-item__header {
      padding: 9px 25px 9px 10px;
      border-radius: 15px;
      border: none;
      height: 38px;

      img {
        background: #fff;
        BORDER-RADIUS: 50%;
      }

      .title {
        font-family: "Raleway Bold", serif;
        font-size: 16px;
        color: #000;
        margin-left: 4px;
        line-height: 20px;

        .phone {
          margin: 0;
          font-family: "Raleway Bold", serif;
          font-size: 16px;
          color: #000000;
        }

        .team-name {
          margin: 0;
          font-family: "Raleway Regular", serif;
          font-size: 14px;
          color: #000000;
        }

        .qrCode {
          font-family: "Raleway Bold", serif;
          font-size: 16px;
          color: #000000;
          margin: 0;
        }

        .type {
          margin: 0;
          font-family: "Raleway Regular", serif;
          font-size: 14px;
          color: #000000;
        }
      }
    }

    .el-collapse-item__wrap {
      background-color: initial;
      border: none;

      .el-collapse-item__content {
        padding: 30px 22px;

        .row {
          display: flex;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 40px;
          }

          .property {
            flex: 2;
            font-family: "Raleway Regular", serif;
            font-size: 16px;
            color: #000;
          }

          .value {
            flex: 2;
            font-family: "Raleway Bold", serif;
            font-size: 16px;
            color: #000;
            text-align: right;

            img {
              border-radius: 50%;
              background: #ffffff;
              width: 50px;
            }
          }
        }
      }
    }
  }
}
