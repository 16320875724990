.vue-image-crop-upload {
  .vicp-operate {
    a {
      color: #FF8A00 !important;
    }
  }

  .vicp-step2 {
    .vicp-range {
      input::-webkit-slider-runnable-track {
        background: #FF8A00 !important;
      }

      input::-webkit-slider-thumb {
        background: #FFFFFF !important;
      }
    }
  }
}
