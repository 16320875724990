.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.7s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-300px);
}

.slide-fade-enter-to,
.slide-fade-leave {
  transform: translateX(0);
}
