.el-tabs {
  &__header {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: none !important;
  }

  &__nav {
    display: flex;
    border-radius: 50px !important;
    background: #0000001A;
    width: 343px;
    border: none !important;
    height: 54px;
  }

  &__item {
    flex: 1;
    font-family: "Raleway Medium", serif;
    font-size: 14px;
    border: none;
    color: #0000005c;
    margin: 4px;
    height: 46px;
    padding: 4px 16px;
    text-align: center;

    &.is-active {
      background: #FFFFFF;
      border-radius: 50px;
      color: #000000;
    }
  }
}
